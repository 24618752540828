import { SorterResult } from "antd/lib/table/interface";

export const getSortValue = <T>(sorter: SorterResult<T> | SorterResult<T>[]) => {
  let value: SorterResult<T>;
  if (sorter instanceof Array) value = sorter[0];
    else value = sorter;

  if (!value.order) return null;

  const order = value.order == "ascend" ? "ASC" : "DESC";
  const result = value.field + ":" + order;
  return result;
}