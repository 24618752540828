import { useState } from "react";

const prefix = "raykov_tg_service_"
export const useLocalStorage = (keyName: string, defaultValue: any) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(prefix + keyName);
      if (value) {
        return JSON.parse(value); 
      } else {
        window.localStorage.setItem(prefix + keyName, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });

  const setValue = (newValue: any) => {
    try {
      window.localStorage.setItem(prefix + keyName, JSON.stringify(newValue));
    } catch (err) {}
    setStoredValue(newValue);
  };
  
  return [storedValue, setValue];
};