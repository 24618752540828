import { useCallback } from "react";
import { IScheduleGroupByAccount } from "../interfaces/schedule-with-account.interface";
import { useFetch } from "./use-fetch-2";
import { IAccountStrapiResponse } from "../interfaces/account-strapi-response.interface";
import { IStrapiResponse, IStrapiResponseArray } from "../interfaces/strapi-response.interface";

export const useApi = () => {
  const fetch = useFetch();

  const getAccounts = useCallback((page: number, limit: number) => {
    return fetch<IStrapiResponseArray<IAccountStrapiResponse>>(`accounts?populate=*&pagination[page]=${page}&pagination[pageSize]=${limit}&`);
  }, [fetch]);

  const getSchedulesGroupByAccounts = useCallback((page: number, limit: number, sort?: string) => {
    return fetch<{ items: IScheduleGroupByAccount[], count: number }>(`posts/with-accounts?limit=${limit}&offset=${(page - 1)*limit}&sort=${sort}`);
  }, [fetch]);

  const getUploadToTelegramStatus = useCallback(() => {
    return fetch<IStrapiResponse<{ status: boolean }>>(`telegram-import-process/`)
  }, [fetch]);

  return { getAccounts, getSchedulesGroupByAccounts, getUploadToTelegramStatus };
}