import { useParams, useNavigate, Link } from 'react-router-dom';
import { Fragment, useEffect, useState, useCallback } from 'react';
import { Button, Card, DatePicker, Divider, Empty, Layout, message, PageHeader, Radio, RadioChangeEvent, Skeleton, Space, Table, Tag, Typography, } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment, { Moment } from 'moment';
import ReactMarkdown from 'react-markdown';
import { IStrapiResponse, IStrapiResponseArray } from '../interfaces/strapi-response.interface';
import { IChat } from '../interfaces/chat.interface';
import Search from 'antd/lib/input/Search';
import { useFetch } from '../hooks/use-fetch-2';
import { IScheduleWithAccount } from '../interfaces/schedule-with-account.interface';
import { openNotification } from '../utils/open-notification';
import { getTagData } from '../utils/get-tag-data';
import { RangePickerProps } from 'antd/lib/date-picker';
import { config } from '../config';
import { PostStatus } from '../enums/post-status.enum';
import { ExclamationCircleOutlined, CheckCircleOutlined, ClockCircleOutlined, SyncOutlined } from '@ant-design/icons';

interface IColumnsData {
  id: number;
  index: number;
  phone: number;
  name: {
    id: number;
    name: string;
  },
  seller: string;
  date: string;
  text: string;
  status: PostStatus;
}

const { RangePicker } = DatePicker;

const columns: ColumnsType<IColumnsData> = [
  {
    title: "№",
    dataIndex: "index",
    key: "index",
    width: "6%",
    align: "center"
  },
  {
    title: "Имя аккаунта",
    dataIndex: "name",
    key: "name", 
    width: "13%",
    render: account => account ? <Link to={`/accounts/${account?.id}`}>{account?.name}</Link> : account?.name,
    sorter: (a, b) => {
      if (a.name?.name > b.name?.name) return 1;
      if (a.name?.name < b.name?.name) return -1;
      return 0;
    }
  },
  {
    title: "Телефон",
    dataIndex: "phone",
    key: "phone",
    width: "14%",
    sorter: (a, b) => {
      if (a?.phone > b?.phone) return 1;
      if (a?.phone < b?.phone) return -1;
      return 0;
    }
  },
  {
    title: "Дата",
    dataIndex: "date",
    key: "date",
    width: "13%",
    render: date => moment(date, "DD.MM.YYYY HH:mm").format("DD.MM.YYYY HH:mm"),
    sorter: (a, b) => {
      if (moment(a.date, "DD.MM.YYYY HH:mm").toDate().getTime() > moment(b.date, "DD.MM.YYYY HH:mm").toDate().getTime()) return 1;
      if (moment(a.date, "DD.MM.YYYY HH:mm").toDate().getTime() < moment(b.date, "DD.MM.YYYY HH:mm").toDate().getTime()) return -1;
      return 0;
    }
  },
  {
    title: "Текст",
    dataIndex: "text",
    key: "text",
    width: "20%",
    render: text => <div style={{ 
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "20vw", 
      whiteSpace: "nowrap"
    }}>{text}</div>
  },
  {
    title: "Данные клиента",
    dataIndex: "seller",
    key: "seller",
    render: text => <div style={{ 
      textOverflow: "ellipsis", 
      overflow: "hidden", 
      maxWidth: "20vw", 
      whiteSpace: "nowrap"
    }}>{text}</div>,
    sorter: (a, b) => {
      if (a.seller > b.seller) return 1;
      if (a.seller < b.seller) return -1;
      return 0;
    }
  },
  {
    title: "Статус",
    dataIndex: "status",
    key: "status",
    render: (text:PostStatus) => {
      const color = text == PostStatus.NOT_LOADED ? "red" : (text == PostStatus.POSTED ? "green" : "default");
      const icon = text == PostStatus.NOT_LOADED ? 
        <ExclamationCircleOutlined /> : (text == PostStatus.POSTED ? <CheckCircleOutlined /> : <ClockCircleOutlined />);
      return <Tag icon={icon} color={color}>{text.toUpperCase()}</Tag>
    }
  }
]

export function Chat() {
  let { id } = useParams();
  const navigate = useNavigate();
  const [chat, setChat] = useState<IChat | null>(null);
  const [schedules, setSchedules] = useState<IColumnsData[]>([]);
  const [filteredSchedules, setFilteredSchedules] = useState<IColumnsData[]>(schedules);
  const [range, setRange] = useState<[Moment, Moment]>([
    moment(new Date().setHours(0, 0, 0, 0)), 
    moment(new Date().setFullYear(new Date().getFullYear() + 1))
  ]);
  const [updateStatusButtonLoading, setUpdateStatusButtonLoading] = useState(false);

  const fetchData = useFetch();

  const getSchedulesData = useCallback(async () => {
    const postDataResponse = await fetchData.get<IStrapiResponseArray<IScheduleWithAccount>>(`chats/${id}/posts`);
    console.log(postDataResponse);
    if (postDataResponse.data.data) {
      const items:IColumnsData[] = [];
      postDataResponse.data.data.forEach((item: any, i: number) => {
        items.push(...item.attributes.schedule?.map((date: string, j: number) => {
          const in_telegram = item.attributes.remaining_dates?.includes(date);
          return {
            ...item.attributes,
            id: item.id,
            index: i + j + 1,
            date: date,
            phone: item.attributes.account?.phone || "-",
            name: item.attributes.account || { name: "-" },
            status: item.attributes.uploaded_to_telegram ? (in_telegram ? PostStatus.LOADED : PostStatus.POSTED) : PostStatus.NOT_LOADED,
            text: item.attributes.account?.text || "-",
            seller: item.attributes.account?.seller || "-"
          }
        }) || []);
      });

      setSchedules(items);
    }
  }, [id]);

  const getChatData = useCallback(async () => {
    try {
      const chatDataResponse = await fetchData.get<IStrapiResponse<IChat>>(`chats/${id}`);
      setChat(chatDataResponse.data.data.attributes);
      
    } catch(e: any) {
      console.log(e);
      if (e?.response?.status == 401) openNotification("Необходима авторизация", undefined, "error");
    }
  }, [id]);

  useEffect(() => {
    getChatData();
    getSchedulesData();
  }, [fetchData]);

  useEffect(() => {
    const filteredSchedules = schedules.filter(item => {
      const date = moment(item.date, "DD.MM.YYYY HH:mm");
      if (date.toDate() >= range[0].toDate() && date.toDate() <= range[1].toDate()) return true;
        else return false;
    }).map((item, i) => {
      return { ...item, index: i + 1 }
    });

    setFilteredSchedules(filteredSchedules);
  }, [schedules, range])

  const onSearch = (value: string) => {
    const filteredData = schedules.filter(schedule => {
      return Object.values(schedule).find(val => {
        if (typeof val == "object" && val && val.name) {
          return String(val.name).toLocaleLowerCase().includes(value.toLocaleLowerCase());
        } else if (String(val).toLocaleLowerCase().includes(value.toLocaleLowerCase())) return true;
      });
    });
    setFilteredSchedules(filteredData);
  }
  
  const onRangeChange = (range: [Moment, Moment]) => {
    console.log(moment(range[0].toDate().setHours(0, 0, 0, 0)), moment(range[1].toDate().setHours(23, 59, 59, 0)));
    setRange([
      moment(range[0].toDate().setHours(0, 0, 0, 0)), 
      moment(range[1].toDate().setHours(23, 59, 59, 0))
    ]);
    return;
  } 

  const updatePostsStatus = async () => {
    setUpdateStatusButtonLoading(true);
    try {
      const response = await fetchData.get(`/chats/${id}/check-schedules`);
      console.log(response);
      await getSchedulesData();
      message.success("Успешно обновлено", 3);
    } catch(e: any) {
      if (e?.response?.status == 404) message.warning(`Активных постов не обнаружено`, 3);
        else if (e?.response?.status == 500) message.error(`Ошибка на стороне сервера: ${e}`, 3);
          else message.error(`Ошибка загрузки: ${e}`, 3);
    } finally {
      setUpdateStatusButtonLoading(false);
    }
  }

  return (<>
    <PageHeader 
      title={chat?.name || <Skeleton.Input active size={"default"} />} 
      subTitle={chat?.chat_id ? `id: ${chat?.chat_id}` : <Skeleton.Button shape="square" active size={"default"} />} 
      onBack={() => navigate(-1)} 
      className="page-header"
    />
    <Layout className={"tableLayout"}>
      {chat ? <Card className ="card" title={<>
        <span style={{marginRight: "15px"}}>ID чата: {chat?.chat_id}</span>
        <Button disabled={!chat.link} type="default" style={{marginRight: "20px"}} onClick={() => window.open(chat.link, "_blank")}>Открыть в телеграм</Button>
        <Button type="primary" onClick={() => window.open(`${config.admin_link}/content-manager/collectionType/api::chat.chat/${id}`, "_blank")}>Открыть в Strapi</Button>
      </>} bordered={false}>

        <Space direction="horizontal" style={{display: "flex", justifyContent: "space-between", marginBottom: "20px", width: "100%"}}>
        <Button onClick={updatePostsStatus} loading={updateStatusButtonLoading} type="primary" icon={<SyncOutlined />}>Обновить статус постов</Button>
          <Space direction="horizontal" style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
            <RangePicker value={range} onChange={onRangeChange as any} />
            <Search 
              placeholder="Поиск по всем полям" 
              onSearch={(value, e) => onSearch(value)} 
              onInput={e => onSearch(e.currentTarget.value)} 
              style={{ width: 250 }} 
            />
          </Space>
        </Space>

          {filteredSchedules.length ? <Table 
            size="small"
            columns={columns} 
            dataSource={filteredSchedules}
            pagination={{ pageSize: 50 }} 
          /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
      </Card>
      : <Card title={<Skeleton.Button active size={"small"}/>} bordered={false}>
          <Skeleton.Input size="small" active/>
          <Divider orientation="left">Таблица с чатами и постами</Divider>
          <Skeleton paragraph active/>
        </Card>
      }

    </Layout>
  </>)
}