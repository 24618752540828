import { useParams, useNavigate, Link } from 'react-router-dom';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { IAccount } from '../interfaces/account.interface';
import { Button, Card, DatePicker, Divider, Empty, Layout, PageHeader, Radio, RadioChangeEvent, Skeleton, Space, Table, Tag, Typography, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment, { Moment } from 'moment';
import ReactMarkdown from 'react-markdown';
import { IStrapiResponse, IStrapiResponseArray, IStrapiData } from '../interfaces/strapi-response.interface';
import { IScheduleWithChat } from '../interfaces/schedule-n-chat.interface';
import Search from 'antd/lib/input/Search';
import { useFetch } from '../hooks/use-fetch-2';
import { openNotification } from '../utils/open-notification';
import { AccountInfoBlock } from './account-info-block';
import { RangePickerProps } from 'antd/lib/date-picker';
import { config } from '../config';
import { IChat } from '../interfaces/chat.interface';
import { PostStatus } from '../enums/post-status.enum';
import { ExclamationCircleOutlined, CheckCircleOutlined, ClockCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { IAccountStrapiResponse } from '../interfaces/account-strapi-response.interface';

const { RangePicker } = DatePicker;

interface IColumnsData {
  index: number;
  chat: IChat;
  date: string;
  text: string;
  seller: string;
  status: PostStatus
}

const columns: ColumnsType<IColumnsData> = [
  {
    title: "№",
    dataIndex: "index",
    key: "index",
    width: "8%"
  },
  {
    title: "Чат",
    dataIndex: "chat",
    key: "chat",
    render: chat => <Link to={`/chats/${chat.id}`}>{chat.name}</Link>,
    sorter: (a, b) => {
      if (a.chat.name > b.chat.name) return 1;
      if (a.chat.name < b.chat.name) return -1;
      return 0;
    } 
  },
  {
    title: "Дата",
    dataIndex: "date",
    key: "date",
    render: (date: string) => {
      return moment(date, "DD.MM.YYYY HH:mm").format("DD.MM.YYYY HH:mm");
    },
    sorter: (a, b) => {
      if (a.date > b.date) return 1;
      if (a.date < b.date) return -1;
      return 0;
    }
  },
  {
    title: "Статус",
    dataIndex: "status",
    key: "status",
    render: (text:PostStatus) => {
      const color = text == PostStatus.NOT_LOADED ? "red" : (text == PostStatus.POSTED ? "green" : "default");
      const icon = text == PostStatus.NOT_LOADED ? 
        <ExclamationCircleOutlined /> : (text == PostStatus.POSTED ? <CheckCircleOutlined /> : <ClockCircleOutlined />);
      return <Tag icon={icon} color={color}>{text.toUpperCase()}</Tag>
    }
  }
]

export function Account() {
  let { id } = useParams();
  const navigate = useNavigate();
  const [account, setAccount] = useState<IAccountStrapiResponse | null>(null);
  const [schedules, setSchedules] = useState<IColumnsData[]>([]);
  const [filteredSchedules, setFilteredSchedules] = useState<IColumnsData[]>(schedules);
  const fetchData = useFetch();
  const [range, setRange] = useState<[Moment, Moment]>([
    moment(new Date().setHours(0, 0, 0, 0)), 
    moment(new Date().setFullYear(new Date().getFullYear() + 1))
  ]);
  const [updateStatusButtonLoading, setUpdateStatusButtonLoading] = useState(false);

  const getPostsData = useCallback(async () => {
    const postDataResponse = await fetchData.get<IStrapiResponseArray<IScheduleWithChat>>(`accounts/${id}/posts`);
    if (postDataResponse.data.data) {
      const items:IColumnsData[] = [];
      postDataResponse.data.data.forEach((item: IStrapiData<IScheduleWithChat>) => {
        items.push(...item.attributes.schedule?.map((date: string, i: number) => {
          const in_telegram = item.attributes.remaining_dates?.includes(date);
          return {
            ...item.attributes,
            index: i + 1,
            id: item.id,
            date: date,
            chat: item.attributes.chat,
            status: item.attributes.uploaded_to_telegram ? (in_telegram ? PostStatus.LOADED : PostStatus.POSTED) : PostStatus.NOT_LOADED
          }
        }));
      });

      setSchedules(items);
    }
  }, [id]);

  const getAccountData = useCallback(async () => {
    try {
      const accountDataResponse = await fetchData.get<IStrapiResponse<IAccountStrapiResponse>>(`accounts/${id}?populate=clients_chat`);
      console.log(accountDataResponse);
      setAccount(accountDataResponse.data.data.attributes);
    
    } catch(e: any) {
      console.log(e);
      if (e?.response?.status == 401) openNotification("Необходима авторизация", undefined, "error");
    }
  }, [id]);

  useEffect(() => {
    getAccountData();
    getPostsData();
  }, [fetchData, getPostsData]);

  useEffect(() => {
    const filteredSchedules = schedules.filter(item => {
      const date = moment(item.date, "DD.MM.YYYY HH:mm");
      if (date.toDate() >= range[0].toDate() && date.toDate() <= range[1].toDate()) return true;
        else return false;
    }).map((item, i) => {
      return { ...item, index: i + 1}
    });

    setFilteredSchedules(filteredSchedules);
  }, [schedules, range]);

  const onSearch = (value: string) => {
    const filteredData = schedules.filter(schedule => {
      return Object.values(schedule).find(val => {
        if (typeof val == "object" && val && val.name) {
          let result = String(val.name).toLocaleLowerCase().includes(value.toLocaleLowerCase());
          return result;

        } else if (String(val).toLocaleLowerCase().includes(value.toLocaleLowerCase())) {
          return true;
        } else if (typeof val == "object" && val && val.date_plan) {
          let status = "ожидание";
          const date_1h_earlier = new Date(new Date().getTime() - 1000 * 60 * 60);
          if (!val.submitted && new Date(val.date_plan) < date_1h_earlier) status = "просрочено";
          if (val.submitted) status = "отправлено";
          return status.includes(value.toLocaleLowerCase())
        }
      });
    });
    setFilteredSchedules(filteredData);
  }

  const onRangeChange = (range: [Moment, Moment]) => {
    setRange([
      moment(range[0].toDate().setHours(0, 0, 0, 0)), 
      moment(range[1].toDate().setHours(23, 59, 59, 0))
    ]);
    return;
  } 

  const updatePostsStatus = async () => {
    setUpdateStatusButtonLoading(true);
    try {
      const response = await fetchData.get(`/accounts/${id}/check-schedules`);
      console.log(response);
      await getPostsData();
      message.success("Успешно обновлено", 3);
    } catch(e: any) {
      if (e?.response?.status == 404) message.warning(`Активных постов не обнаружено`, 3);
        else if (e?.response?.status == 500) message.error(`Ошибка на стороне сервера: ${e}`, 3);
          else message.error(`Ошибка загрузки: ${e}`, 3);
    } finally {
      setUpdateStatusButtonLoading(false);
    }
  }

  return (<>
    <PageHeader 
      title={account?.name ? `Аккаунт: ${account?.name}` : <Skeleton.Input active size={"default"} />} 
      subTitle={account?.phone ? account?.phone : <Skeleton.Button shape="square" active size={"default"} />} 
      onBack={() => navigate(-1)} 
      className="page-header"
    />
    <Layout className={"tableLayout"}>
      {account ? <Card className ="card" title={<>
        <span style={{marginRight: "20px"}}>{account?.phone}</span>
        <Button type="primary" onClick={() => window.open(`${config.admin_link}/content-manager/collectionType/api::account.account/${id}`, "_blank")}>Открыть в Strapi</Button>
      </>} bordered={false}>
        <Typography className='item-info'>
          <AccountInfoBlock id={id} account={account}/>
          <br/>
        </Typography>
        <br/>
          
        <Space direction="horizontal" style={{display: "flex", justifyContent: "end", marginBottom: "20px", width: "100%"}}>
          <Button onClick={updatePostsStatus} loading={updateStatusButtonLoading} type="primary" icon={<SyncOutlined />}>Обновить статус постов</Button>
          <RangePicker value={range} onChange={onRangeChange as any} />
          <Search 
            placeholder="Поиск по всем полям" 
            onSearch={(value, e) => onSearch(value)} 
            onInput={e => onSearch(e.currentTarget.value)} 
            style={{ width: 250 }} 
          />
        </Space>
        {filteredSchedules.length ? <Table 
          size="small"
          columns={columns} 
          dataSource={filteredSchedules}
          pagination={{ pageSize: 50 }}
        /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
      </Card> : 
      <Card title={<Skeleton.Button active size={"small"}/>} bordered={false}>
        <Skeleton paragraph active/>
        <Divider orientation="left">Таблица с чатами и постами</Divider>
        <Skeleton paragraph active/>
      </Card>
      }
    </Layout>
  </>)
}