import { message, UploadProps } from "antd";
import { getJWT } from "./get-jwt";

export function getUploadProps(url: string) {
  const uploadProps: UploadProps = {
    multiple: false,
    accept: ".txt,.csv",
    name: "file",
    action: url,
    headers: {
      Authorization: `Bearer ${getJWT()}`,
    },
    onChange(info) {
      console.log(info);
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} файл успешно загружен, записи созданы`);
      } else if (info.file.status === 'error') {
        console.error(info.file.response);
        message.error(`${info.file.name} - произошла ошибка: ${info.file.response.message || info.file.response}`);
      }
    },
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: percent => percent && `${parseFloat(percent.toFixed(2))}%`,
    }
  }

  return uploadProps
}