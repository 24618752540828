import { PageHeader, Layout, Skeleton, Space, Empty, Tag, Button, Upload, UploadProps, message, TablePaginationConfig } from 'antd';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import "./pages.scss";

import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Fragment, useEffect, useState } from "react";
import { IAccount } from '../interfaces/account.interface';
import { IStrapiResponseArray } from '../interfaces/strapi-response.interface';
import { SearchOutlined } from "@ant-design/icons";
import Search from 'antd/lib/input/Search';
import { config } from '../config';
import { useFetch, getAxiosInstance } from '../hooks/use-fetch-2';
import { openNotification } from '../utils/open-notification';
import { CloseCircleOutlined, CheckCircleOutlined, ExclamationCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { getJWT } from '../utils/get-jwt';
import { getUploadProps } from '../utils/get-upload-props';
import ReactMarkdown from 'react-markdown';
import { IAccountStrapiResponse } from '../interfaces/account-strapi-response.interface';
import { useApi } from '../hooks/use-api';
import { useQuery } from 'react-query';

interface DataType {
  index: number;
  id: number;
  account_id: number;
  name: {
    id: number;
    name: string;
  };
  phone: string;
  clients_chat: {
    id?: number,
    name?: string,
    link?: string;
  },
  gender: string
  tg_auth?: boolean;
}

function getTagData(tgAuth: boolean | null) {
  if (tgAuth == false) return {
    icon: <CloseCircleOutlined />,
    color: "error",
    text: "Не авторизован"
  }

  if (tgAuth == true) return {
    icon: <CheckCircleOutlined />,
    color: "success",
    text: "Авторизован"
  }

  if (tgAuth == null) return {
    icon: <ExclamationCircleOutlined />,
    color: "warning",
    text: "Нет данных",
  }
}

const columns: ColumnsType<DataType> = [
  {
    title: "№",
    dataIndex: "index",
    key: "index",
    width: "6%",
    align: "center"
  },
  {
    title: "Статус в системе",
    dataIndex: "tg_auth",
    key: "tg_auth",
    width: "10%",
    render: tg_auth => {
      const tagData = getTagData(tg_auth);
      return <Tag icon={tagData?.icon} 
        color={tagData?.color}
      >{tagData?.text}
      </Tag>
    },
    defaultSortOrder: "descend",
    sorter: (a, b) => {
      return Number(a.tg_auth) - Number(b.tg_auth);
    }
  },
  {
    title: "Телефон",
    dataIndex: "phone",
    key: "phone",
    width: "12%",
    sorter: (a, b) => {
      if (a.phone > b.phone) return 1;
      if (a.phone < b.phone) return -1;
      return 0;
    }
  },
  {
    title: "Имя",
    dataIndex: "name",
    key: "name",
    width: "15%",
    render: account => <Link to={`/accounts/${account.id}`}>{account.name}</Link>,
    sorter: (a, b) => {
      if (a.name.name > b.name.name) return 1;
      if (a.name.name < b.name.name) return -1;
      return 0;
    }
  },
  {
    title: "Объявление",
    dataIndex: "text",
    key: "text",
    className: "text-column",
    render: text => <ReactMarkdown
      source={text}
      escapeHtml={false}
      renderers={{ paragraph: props => <Fragment children={props.children} /> }}
    />
  },
  {
    title: "Продавец",
    dataIndex: "seller",
    key: "seller",
    width: "16%",
    className: "text-column"
  },
  {
    title: "Системный чат",
    dataIndex: "clients_chat",
    key: "clients_chat",
    render: clients_chat => {
      return <Space align="center" style={{display: "flex", justifyContent: "space-between", paddingRight: "15px"}}>
        <Link to={`/clients-chats/${clients_chat.id}`}>{clients_chat.name}</Link>
      </Space>
    },
    sorter: (a, b) => {
      if (a.name.name > b.name.name) return 1;
      if (a.name.name < b.name.name) return -1;
      return 0;
    } 
  },
  {
    title: "Пол",
    dataIndex: "gender",
    key: "gender",
    width: "5%"
  }
]

export function Accounts() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams({ page: "1", pageSize: "50" });
  const [total, setTotal] = useState(0);
  const [accounts, setAccounts] = useState<DataType[]>([]);
  const [filteredAccounts, setFilteredAccounts] = useState(accounts);
  const { getAccounts } = useApi();
  const { data: accountsData, isLoading, isFetched } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ["accounts", { pageSize: searchParams.get("pageSize"), page: searchParams.get("page") }],
    queryFn: () => getAccounts(Number(searchParams.get("page")), Number(searchParams.get("pageSize"))).then(response => response.data)
  });

  console.log(accountsData, accounts);

  useEffect(() => {
    if (accountsData?.data && accountsData?.meta && isFetched) {
      setTotal(accountsData.meta?.pagination?.total);
      const arr = accountsData.data.map((item, i) => ({
          ...item.attributes, 
          index: i + 1,
          id: item.id,
          name: { id: item.id, name: item.attributes.name },
          clients_chat: {
            name: item.attributes.clients_chat?.data?.attributes?.name,
            link: item.attributes.clients_chat?.data?.attributes?.link,
            id: item.attributes.clients_chat?.data?.id
          },
      }));

      setAccounts(arr);
    }
  }, [accountsData, isFetched]);

  useEffect(() => {
    setFilteredAccounts(accounts);
  }, [accounts]);

  const onSearch = (value: string) => {
    const filteredData = accounts.filter(account => {
      return Object.values(account).find(val => {
        if (typeof val == "object" && val.name) {
          return String(val.name).toLocaleLowerCase().includes(value.toLocaleLowerCase());
        } else if (String(val).toLocaleLowerCase().includes(value.toLocaleLowerCase())) return true;
      });
    });
    setFilteredAccounts(filteredData);
  }

  const onPaginationChange = (e: TablePaginationConfig) => {
    setSearchParams(prevState => {
      let newPage = String(e.current);
      let newPageSize = String(e.pageSize || 50);
      if (prevState.get("pageSize") != newPageSize) newPage = "1";
      
      return { page: newPage, pageSize: newPageSize };
    });
  }

  return (<>
    <PageHeader title="Аккаунты" onBack={() => navigate(-1)} className="page-header"/>
    <Layout className={"tableLayout"}>
      <Space style={{ marginBottom: "20px", justifyContent: "space-between" }}>
        <Upload {...getUploadProps(`${config.api_url}/accounts/batch-create`)}>
          <Button icon={<UploadOutlined />}>Загрузить аккаунты из csv</Button>
        </Upload>
        <Space align="end" direction="horizontal" style={{ justifyContent: "end" }}>
          <Button type="primary" onClick={() => window.open(`${config.admin_link}/content-manager/collectionType/api::account.account?page=1&pageSize=20&sort=name:ASC`, "_blank")}>Открыть в Strapi</Button>
          <Search 
            placeholder="Поиск по всем полям" 
            onSearch={(value, e) => onSearch(value)} 
            onInput={e => onSearch(e.currentTarget.value)} 
            style={{ width: 250 }} 
          />
        </Space>
      </Space>
      {(isLoading || !accounts.length) ? 
        <Skeleton active paragraph={{ rows: 10 }} /> : 
        (filteredAccounts.length ? <Table 
          size="middle"
          className="table"
          columns={columns} 
          dataSource={filteredAccounts}
          pagination={{ 
            pageSize: Number(searchParams.get("pageSize")), 
            total, 
            current: Number(searchParams.get("page")) 
          }} 
          onChange={onPaginationChange}
        /> :  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>)}
    </Layout>
  </>)
}