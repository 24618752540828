import { notification } from "antd";

type NotificationType = 'success' | 'info' | 'warning' | 'error';

export const openNotification = (message: string, description?: string, type:NotificationType = "info", duration=2) => {
  notification[type]({
    message,
    description,
    placement: "top",
    duration
  });
};