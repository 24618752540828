import { Alert, Button, Empty, Layout, message, Modal, PageHeader, Skeleton, Space, Table, Tag, Upload } from "antd";
import { UploadOutlined, WarningOutlined, SyncOutlined } from '@ant-design/icons';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFetch } from "../hooks/use-fetch-2";
import Search from "antd/lib/input/Search";
import { config } from "../config";
import { ColumnsType } from "antd/lib/table";
import { getUploadProps } from '../utils/get-upload-props';
import moment from "moment";
import { pause } from "../utils/pause";
import { TablePaginationConfig } from "antd/es/table";
import { useQuery } from "react-query";
import { useApi } from "../hooks/use-api";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import { getSortValue } from "../utils/get-sorter-value";

interface DataType {
  num: number;
  account_name: {
    name: string;
    id: number
  };
  account_phone: string;
  chatsCount: number;
  date: string;
  time: string;
  import_status: { total: number, pass: number, onClick: () => void };
}

const columns: ColumnsType<DataType> = [
  {
    title: "№",
    dataIndex: "num",
    key: "num",
    width: "6%",
    align: "center"
  },
  {
    title: "Статус импорта в телеграм",
    dataIndex: "import_status",
    key: "import_status",
    render: ({ pass, total, onClick, buttonAvailability }) => {
      return <>
        <ImportButton pass={pass} total={total} onClick={onClick} buttonAvailability={buttonAvailability}/>
      </>
    },
    sorter: (a, b) => {
      return Number(a.import_status.pass) - Number(b.import_status.pass);
    },
    // defaultSortOrder: "ascend",
    width: "24%"
  },
  {
    title: "Телефон",
    dataIndex: "account_phone",
    key: "account_phone",
    sorter: () => 0
  },
  {
    title: "Имя аккаунта",
    dataIndex: "account_name",
    key: "account_name",
    render: account => <Link to={`/accounts/${account.id}`}>{account.name}</Link>,
    sorter: (a, b) => {
      if (a.account_name.name > b.account_name.name) return 1;
      if (a.account_name.name < b.account_name.name) return -1;
      return 0;
    }
  },
  {
    title: "Пуб чаты",
    dataIndex: "chatsCount",
    key: "chatsCount",
    width: "10%"
  },
  {
    title: "Схема: Дата",
    dataIndex: "date",
    key: "date",
    width: "9%"
  },
  {
    title: "Схема: Время",
    dataIndex: "time",
    key: "time",
    width: "9%"
  }
]

function ImportButton({ pass, total, onClick, buttonAvailability }: { pass: number, total: number, buttonAvailability: boolean, onClick: () => void }) {
  console.log("buttonAvailability", buttonAvailability);
  const progress = 100 * pass/total;
  const [buttonAvailable, setButtonAvailable] = useState(buttonAvailability);
  const clickHandler = async () => {
    setButtonAvailable(false);
    onClick();
  }

  useEffect(() => {
    setButtonAvailable(buttonAvailability);
  }, [buttonAvailability]);

  let color = "#dc9898"; 
  if (progress > 30) color = "#f2d5a2";
  if (progress > 50) color = "#d6d65f";
  if (progress > 75) color = "#c2e66c";
  if (progress >= 99) color = "#6cd9ac";

  return <>
    <Space style={{width: "100%", display: "flex"}}>
      <div style={{ position: "relative", width: "108px", height: "20px", background: "#eee", border: "1px solid #ccc"}}>
        <div style={{ width: Math.min(98, progress) + "%", height: "16px", background: color, margin: "1px"}}></div>
        <div style={{ position: "absolute", top: "0", fontSize: "12px", width: "100%", textAlign: "center", color: "#666"}}>{pass}/{total}</div>
      </div>
      {progress < 99 && <Button disabled={!buttonAvailable} type="primary" size="small" onClick={clickHandler}>Импортировать</Button>}
    </Space>
  </>
}

export function Schedules() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams({ page: "1", pageSize: "50", sort: "account_phone:ASC" });
  const [schedules, setSchedules] = useState<DataType[]>([]);
  const [filteredSchedules, setFilteredSchedules] = useState(schedules);
  const fetchData = useFetch();
  const [modalStatus, setModalStatus] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [uploadToTelegramButtonAvailability, setUploadToTelegramButtonAvailability] = useState(false);
  const [maxDate, setMaxDate] = useState<string>();
  const [total, setTotal] = useState(0);
  const { getSchedulesGroupByAccounts, getUploadToTelegramStatus } = useApi();
  const { data: accountsData, isLoading } = useQuery({
    queryKey: ["schedulesGroupByAccounts", { pageSize: searchParams.get("pageSize"), page: searchParams.get("page"), sort: searchParams.get("sort") }],
    queryFn: () => getSchedulesGroupByAccounts(
      Number(searchParams.get("page")), 
      Number(searchParams.get("pageSize")), 
      String(searchParams.get("sort"))
    ).then(response => response.data),
    refetchOnWindowFocus: false
  });

  const { data: uploadToTelegramStatus, isLoading: uploadingStatusIsLoading } = useQuery({
    queryKey: ["uploadToTelegramStatus"],
    queryFn: () => getUploadToTelegramStatus().then(response => response.data.data),
    refetchOnWindowFocus: false
  })

  useEffect(() => {
    if (uploadToTelegramStatus?.attributes) {
      setUploadToTelegramButtonAvailability(!uploadToTelegramStatus.attributes.status);
    }
  }, [uploadToTelegramStatus]);

  const uploadAllPostsToTelegram = async () => {
    setUploadToTelegramButtonAvailability(false);
    let hide: any;
    try {
      hide = message.loading("Загрузка постов...", 0);
      await fetchData.get(`posts/upload-to-telegram`);
      await pause(1000);
      message.success("Посты будут загружены в фоновом режиме");
    } catch(e) {
      message.error(`Что-то пошло не так. ${e}`);
    } finally {
      hide();
    }
  } 

  const uploadPostsForAccountToTelegram = async (accountId: number) => {
    if (accountId == undefined) {
      message.error("Отсутствует id аккаунта");
      return;
    }

    setUploadToTelegramButtonAvailability(false);

    let hide: any;
    try {
      hide = message.loading("Загрузка постов...", 0);
      await fetchData.get(`accounts/${accountId}/upload-to-telegram`);
      await pause(1000);
      message.success("Посты будут загружены в фоновом режиме");
    } catch(e) {
      message.error(`Что-то пошло не так. ${e}`);
    } finally {
      hide();
    }
  }

  // useEffect(() => {
  //   const dates = schedules.map(schedule => schedule.schedules.map(item => item.schedule)).flat(2).map(date => moment(date, "DD.MM.YYYY HH:mm").toDate());
  //   console.log(dates);
  //   const maxDate = Math.max(...dates.map(date => date.getTime()));
  //   setMaxDate(moment(new Date(maxDate)).format("DD.MM.YYYY HH:mm").toString());
  //   console.log("maxDate", maxDate);
  // }, [schedules]);

  useEffect(() => {
    if (accountsData) {
      setTotal(Number(accountsData.count));
      console.log(uploadToTelegramButtonAvailability);

      if (accountsData.items?.length) {
        const arr = accountsData.items.map((item, i) => {
          const [schemaDate, schemaTime] = item.schedule_schema?.split(";") || [];

          return {
            num: item.num,
            account_phone: item.account_phone,
            account_name: {
              name: item.account_name,
              id: item.id
            },
            chatsCount: item.chats_count,
            date: schemaDate,
            time: schemaTime,
            import_status: {
              total: item.chats_count,
              pass: Number(item.import_status) || 0,
              onClick: uploadPostsForAccountToTelegram.bind(undefined, item.id),
              buttonAvailability: uploadToTelegramButtonAvailability
            }
          }
        });
        setSchedules(arr);
      }

    }
  }, [accountsData, uploadToTelegramButtonAvailability]);

  useEffect(() => {
    setFilteredSchedules(schedules);
  }, [schedules]);

  const onSearch = (value: string) => {
    const filteredData = schedules.filter(schedule => {
      return Object.values(schedule).find(val => {
        if (typeof val == "object" && val.name) {
          return String(val.name).toLocaleLowerCase().includes(value.toLocaleLowerCase());
        } else if (String(val).toLocaleLowerCase().includes(value.toLocaleLowerCase())) return true;
      });
    });
    setFilteredSchedules(filteredData);
  }

  const openModal = () => {
    setModalStatus(true);
  }

  const updateSchedulesForAll = async () => {
    setConfirmLoading(true);
    try {
      await fetchData.put(`posts/update-schedules`);
      setModalStatus(false);
      setConfirmLoading(false);
      message.success("Расписания успешно обновлены!");
    } catch (e) {
      setConfirmLoading(false);
      message.error(`Произошла ошибка: ${e}`);
    }
  }

  const onTableChange = (e: TablePaginationConfig, filters: any, sorter: SorterResult<DataType> | SorterResult<DataType>[]) => {
    console.log(sorter);
    setSearchParams(prevState => {
      let newPage = String(e.current);
      let newPageSize = String(e.pageSize || 50);
      let newSort = getSortValue<DataType>(sorter) || String(prevState.get("sort"));
      if (prevState.get("pageSize") != newPageSize) newPage = "1";
      
      return { page: newPage, pageSize: newPageSize, sort: newSort };
    });
  }

  const stopImport = async () => {
    try {
      const result = await fetchData(`posts/stop-import`);
      console.log(result);
      message.success("Импорт будет остановлен в течение 10 минут", 4);
    } catch(e) {
      message.error(`Что-то пошло не так: ${e}`, 4);
    }
  }

  return (<>
    <Modal
      title="Вы действительно хотите обновить расписания у всех постов?"
      centered={true}

      open={modalStatus}
      onOk={updateSchedulesForAll}
      confirmLoading={confirmLoading}
      onCancel={() => setModalStatus(false)}
    > 
      <div style={{textAlign: "center"}}>
        <WarningOutlined style={{fontSize: "50px", color: "orange" }}  />
        <p>Текущие отложенные сообщения в телеграме для всех аккаунтов будут удалены</p>
      </div>
    </Modal>
    <PageHeader title="Расписания" onBack={() => navigate(-1)} className="page-header"/>
    <Layout className={"tableLayout"}>
      {(!uploadToTelegramButtonAvailability && !uploadingStatusIsLoading) && <>
        <Alert 
          message="Посты находятся в процессе загрузки. Возможность импорта временно недоступна" 
          type="warning" 
          showIcon
        /><br/>
      </>}
      <Space align="start" style={{marginBottom: "10px"}}>
        <Upload {...getUploadProps(`${config.api_url}/posts/batch-create`)}>
          <Button icon={<UploadOutlined />}>Загрузить расписания из csv</Button>
        </Upload>
        <Button disabled={!uploadToTelegramButtonAvailability} type="default" onClick={uploadAllPostsToTelegram}>Импортировать в телеграм незагруженные сообщения</Button>
        {(!uploadToTelegramButtonAvailability && !uploadingStatusIsLoading) && <Button danger type="primary" onClick={stopImport}>Остановить импорт в телеграм</Button>}
      </Space>
      <Space style={{ marginBottom: "20px", justifyContent: "space-between" }}>
        <Space style={{ height: "30px", fontSize: "16px"}}>
          {(maxDate && maxDate != "Invalid date") && <> Последняя дата публикации: <b>{maxDate}</b></>}
          <Button danger onClick={openModal}>Создать новое расписание для всех аккаунтов</Button>
        </Space>
        <Space align="end" direction="horizontal" style={{ justifyContent: "end" }}>
          <Button type="primary" onClick={() => window.open(`${config.admin_link}/content-manager/collectionType/api::post.post?page=1&pageSize=20&sort=name:ASC`, "_blank")}>Открыть в Strapi</Button>
          <Search 
            placeholder="Поиск по всем полям" 
            onSearch={(value, e) => onSearch(value)} 
            onInput={e => onSearch(e.currentTarget.value)} 
            style={{ width: 250 }} 
          />
        </Space>
      </Space>
      {isLoading ? 
        <Skeleton active paragraph={{ rows: 10 }} /> :
        (filteredSchedules.length ? <Table 
          size="middle"
          className="table"
          columns={columns} 
          dataSource={filteredSchedules}
          onChange={onTableChange}
          pagination={{ 
            pageSize: Number(searchParams.get("pageSize")), 
            total, 
            current: Number(searchParams.get("page")) 
          }}
        /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>)}
    </Layout>
  </>)
}