import { createCache } from '@react-hook/cache';
import axios, { AxiosRequestConfig } from 'axios';
import { useMemo } from 'react';
import { config } from '../config';
import { useLocalStorage } from './use-local-storage';
import { getJWT } from '../utils/get-jwt';

export function getAxiosInstance(auth: boolean = true) {
  const jwt = getJWT();

  const instance = axios.create({
    baseURL: config.api_url,
    timeout: 360000,
    headers: auth ? {'Authorization': `Bearer ${jwt}`} : undefined,
  });

  return instance;
}

export const fetchCache = createCache(async (url, options?: AxiosRequestConfig, auth: boolean = true) => {
  const [jwt] = useLocalStorage("jwt", null); 

  const instance = useMemo(() => axios.create({
    baseURL: config.api_url,
    timeout: 360000,
    headers: auth ? {'Authorization': `Bearer ${jwt}`} : undefined
  }), [jwt, auth]);

  const response = await instance(url, options);
  const data = response.data;
  return data;
});


export const useFetch = (auth: boolean = true) => {
  const [jwt] = useLocalStorage("jwt", null); 
  
  const instance = useMemo(() => axios.create({
    baseURL: config.api_url,
    timeout: 360000,
    headers: auth ? {'Authorization': `Bearer ${jwt}`} : undefined
  }), [jwt, auth]);

  return instance;
}