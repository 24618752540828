import { Layout } from "antd";
import { SidebarMenu } from "./sidebar-menu";
import {
  DatabaseOutlined,
  CommentOutlined,
  ShareAltOutlined,
  TeamOutlined,
  ControlOutlined,
  ContactsOutlined,
  ApiOutlined,
  UnorderedListOutlined,
  UserOutlined,
  BuildOutlined
} from '@ant-design/icons';
import { useMemo, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { config } from '../config';

const { Content, Sider } = Layout;

const items = [
  { 
    label: 'Настройки', key: '1', icon: <DatabaseOutlined />, 
    children: [
      { label: 'Аккаунты', key: 'sub1', icon: <TeamOutlined />, link: "/accounts?page=1&pageSize=50" },
      { label: 'Расписания', key: 'sub2', icon: <UnorderedListOutlined />, link: "/schedules?page=1&pageSize=50&sort=import_status:ASC"},
      { label: 'Публичные Чаты', key: 'sub3', icon: <CommentOutlined />, link: "/chats" },
      { label: 'Системные чаты', key: 'sub4', icon: <ContactsOutlined />, link: "/clients-chats" },
      { label: 'Шаблоны ответов', key: 'sub5', icon: <BuildOutlined />, link: '/templates'}
    ]
  },
  { label: 'Системный аккаунт', key: '2', icon: <UserOutlined />, link: "/system-account" },
  { label: 'Telegram', key: '3', icon: <ShareAltOutlined />, link: "/telegram" },
  { label: 'Прокси', key: '4', icon: <ApiOutlined />, link: '/proxy' },
  { label: 'Админ панель', key: 'admin', icon: <ControlOutlined />, link: config.admin_link },
];

export function MainLayout() {
  const [collapsed, setCollapsed] = useState(false);
  const { pathname } = useLocation();

  const activeItemKey = useMemo(() => {
    for (let item of items) {
      if (item.children) {
        const key = item.children.find(child => child.link == pathname)?.key;
        if (key) return key;
      }
      if (item.link == pathname) return item.key;
    }
    return undefined;
  }, [pathname]);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider width={220} collapsible collapsed={collapsed} onCollapse={value => setCollapsed(value)}>
        <div className="logo" />
        <SidebarMenu items={items} activeItemKey={activeItemKey}/>
      </Sider>
      <Layout className="site-layout">
        <Outlet/>
      </Layout>
  </Layout>
  )
}