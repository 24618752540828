import { PageHeader, Layout, Skeleton, Space, Empty, DatePicker, Button, Upload, Alert, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState, useCallback } from 'react';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import { IStrapiResponseArray } from '../interfaces/strapi-response.interface';
import "./pages.scss";
import Search from 'antd/lib/input/Search';
import { IChat } from '../interfaces/chat.interface';
import { useFetch } from '../hooks/use-fetch-2';
import { openNotification } from '../utils/open-notification';
import { IScheduleWithChat } from '../interfaces/schedule-n-chat.interface';
import moment, { Moment } from 'moment';
import { RangePickerProps } from 'antd/lib/date-picker';
import { config } from '../config';
import { UploadOutlined } from '@ant-design/icons';
import { getUploadProps } from '../utils/get-upload-props';
import { useQuery } from 'react-query';
import { useApi } from '../hooks/use-api';
import { pause } from '../utils/pause';

interface DateType {
  index: number;
  id: number;
  chat_id: number;
  name: {
    id: number;
    name: string;
  },
  count: number,
  status: {
    total: number,
    uploaded: number
  }
}

const { RangePicker } = DatePicker;

const columns: ColumnsType<DateType> = [
  {
    title: "№",
    dataIndex: "index",
    key: "index",
    width: "6%",
    align: "center"
  },
  {
    title: "ID чата",
    dataIndex: "chat_id",
    key: "chat_id",
    width: "10%",
    sorter: (a, b) => a.chat_id - b.chat_id
  },
  {
    title: "Статус",
    dataIndex: "status",
    key: "status",
    width: "10%",
    render: (data) => {
      const progress = 100 * Number(data.uploaded)/Number(data.total);
      let color = "#dc9898"; 
      if (progress > 30) color = "#f2d5a2";
      if (progress > 50) color = "#d6d65f";
      if (progress > 75) color = "#c2e66c";
      if (progress >= 99) color = "#6cd9ac";
      return <div style={{ position: "relative", width: "108px", height: "20px", background: "#eee", border: "1px solid #ccc"}}>
        <div style={{ width: Math.min(98, progress) + "%", height: "16px", background: color, margin: "1px"}}></div>
        <div style={{ position: "absolute", top: "0", fontSize: "12px", width: "100%", textAlign: "center", color: "#666"}}>{data.uploaded}/{data.total}</div>
      </div>
    }
  },
  {
    title: "Название",
    dataIndex: "name",
    key: "name",
    width: "35%",
    render: chat => (
      <Space align="center" style={{display: "flex", justifyContent: "space-between", paddingRight: "15px"}}>
        <Link to={`/chats/${chat.id}`}>{chat.name}</Link>
        <Button disabled={!chat.link} type="default" onClick={() => window.open(chat.link, "_blank")}>Открыть в телеграм</Button>
      </Space>
    ),
    sorter: (a, b) => {
      if (a.name.name > b.name.name) return 1;
      if (a.name.name < b.name.name) return -1;
      return 0;
    }
  },
  {
    title: "Кол-во запланированных постов",
    dataIndex: "count",
    key: "count",
    width: "15%",
    render: count => count,
    sorter: (a, b) => (a.count - b.count)
  },
]

export function Chats() {
  const navigate = useNavigate();
  const [chats, setChats] = useState<DateType[]>([]);
  const [filteredChats, setFilteredChats] = useState<DateType[]>(chats);
  const [uploadToTelegramButtonAvailability, setUploadToTelegramButtonAvailability] = useState(false);
  const [range, setRange] = useState<[Moment, Moment]>([
    moment(new Date().setHours(0, 0, 0, 0)), 
    moment(new Date().setFullYear(new Date().getFullYear() + 1))
  ]);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const fetchData = useFetch();
  const { getUploadToTelegramStatus } = useApi();

  const { data: uploadToTelegramStatus, isLoading: uploadingStatusIsLoading } = useQuery({
    queryKey: ["uploadToTelegramStatus"],
    queryFn: () => getUploadToTelegramStatus().then(response => response.data.data),
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    if (uploadToTelegramStatus?.attributes) {
      setUploadToTelegramButtonAvailability(!uploadToTelegramStatus.attributes.status);
    }
  }, [uploadToTelegramStatus]);

  useEffect(() => {
    getChatsData();
    
    async function getChatsData() {
      try {
        const chatsDataResponse = await fetchData.get<IStrapiResponseArray<IChat>>(`chats?populate=*&pagination[page]=1&pagination[pageSize]=200`);
        const scheduelsCountResponse = await fetchData.get<any []>(`posts/schedules-count`);
        let schedulesCount = scheduelsCountResponse.data;
        
        if (chatsDataResponse.data.data) {
          const arr = chatsDataResponse.data.data.map((item, i) => ({
            ...item.attributes,
            index: i + 1,
            id: item.id,
            count: schedulesCount.find(val => val.id == item.id)?.count || 0,
            status: {
              total: schedulesCount.find(val => val.id == item.id)?.totalPosts || 0,
              uploaded: schedulesCount.find(val => val.id == item.id)?.uploadedGTE20 || 0,
            },
            name: { id: item.id, name: item.attributes.name, link: item.attributes.link }
          }));
    
          setChats(arr);
        }
      } catch(e: any) {
        console.log(e);
        if (e?.response?.status == 401) openNotification("Необходима авторизация", undefined, "error");
      }
    }
  }, [fetchData]);

  useEffect(() => {
    setFilteredChats(chats);
  }, [chats]);

  const onSearch = (value: string) => {
    const filteredData = chats.filter(chat => {
      return Object.values(chat).find(val => {
        if (typeof val == "object" && val && val.name) {
          return String(val.name).toLocaleLowerCase().includes(value.toLocaleLowerCase());
        } else if (String(val).toLocaleLowerCase().includes(value.toLocaleLowerCase())) return true;
      });
    });
    setFilteredChats(filteredData);
  }

  const onRangeChange = (range: [Moment, Moment]) => {
    setRange([
      moment(range[0].toDate().setHours(0, 0, 0, 0)), 
      moment(range[1].toDate().setHours(23, 59, 59, 0))
    ]);
    return;
  } 

  const disabledDate: RangePickerProps['disabledDate'] = useCallback((current: Moment) => {
    return current && current < moment(new Date().setHours(0, 0, 0, 0));
  }, []);

  const uploadToTelegram = async () => {
    setUploadToTelegramButtonAvailability(false);

    let hide: any;
    try {
      hide = message.loading("Загрузка постов...", 0);
      await fetchData.post(`posts/upload-to-telegram-by-chats`);
      await pause(1000);
      message.success("Посты будут загружены в фоновом режиме");
    } catch(e) {
      message.error(`Что-то пошло не так. ${e}`);
    } finally {
      hide();
    }
  }

  const stopImport = async () => {
    try {
      const result = await fetchData(`posts/stop-import`);
      console.log(result);
      message.success("Импорт будет остановлен в течение 10 минут", 4);
    } catch(e) {
      message.error(`Что-то пошло не так: ${e}`, 4);
    }
  }

  return (<>
    <PageHeader title="Публичные чаты" onBack={() => navigate(-1)} className="page-header"/>
    <Layout className={"tableLayout"}>
      {(!uploadToTelegramButtonAvailability && !uploadingStatusIsLoading) && <>
        <Alert 
          message="Посты находятся в процессе загрузки. Возможность импорта временно недоступна" 
          type="warning" 
          showIcon
        /><br/>
      </>}
      <Space direction='vertical' style={{ marginBottom: "10px" }}>
        <Upload {...getUploadProps(`${config.api_url}/chats/batch-create`)}>
          <Button icon={<UploadOutlined />}>Загрузить чаты из csv</Button>
        </Upload>
      </Space>
      <Space style={{ marginBottom: "20px", justifyContent: "space-between" }}>
          <Button disabled={!uploadToTelegramButtonAvailability} type="default" onClick={uploadToTelegram}>Импортировать в телеграм сообщения</Button>
          {(!uploadToTelegramButtonAvailability && !uploadingStatusIsLoading) && <Button danger type="primary" onClick={stopImport}>Остановить импорт в телеграм</Button>}
        <Space direction='horizontal' style={{ justifyContent: "end" }}>
          <Button type="primary" onClick={() => window.open(`${config.admin_link}/content-manager/collectionType/api::chat.chat?page=1&pageSize=10&sort=name:ASC`, "_blank")}>Открыть в Strapi</Button>
          <Space align="end" direction="horizontal" style={{display: "flex", justifyContent: "end", width: "100%"}}>
            <RangePicker disabledDate={disabledDate} value={range} onChange={onRangeChange as any} />
            <Search 
              placeholder="Поиск по всем полям" 
              onSearch={(value, e) => onSearch(value)} 
              onInput={e => onSearch(e.currentTarget.value)} 
              style={{ width: 250 }} 
            />
          </Space>
        </Space>
      </Space>
      {chats.length ? 
        (filteredChats.length ? <Table 
          size="middle"
          className="table"
          columns={columns} 
          dataSource={filteredChats}
          pagination={{ pageSize: 50 }} 
        /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>)
        : <Skeleton active paragraph={{ rows: 10 }} />
      }
      
    </Layout>
  </>)
}