import { Layout, PageHeader, Space, Switch, Typography } from 'antd';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from "react-router-dom";
import { getAxiosInstance } from '../hooks/use-fetch-2';
import { openNotification } from '../utils/open-notification';

export function Telegram() {
  const navigate = useNavigate();
  const [messagingButtonStatus, setMessagingButtonStatus] = useState(false);
  const [forwardingButtonStatus, setForwardingButtonStatus] = useState(false);
  const [isMessagingLoading, setIsMessagingLoading] = useState(false);
  const [isForwardingLoading, setIsForwardingLoading] = useState(false);

  const { isFetching, data } = useQuery('cron-job', () =>
    getAxiosInstance().get(`telegram-cron-job`).then(resp => {
      return resp.data?.data?.attributes;
    }).catch(e => {
      throw e.response?.data;
    }),
  { refetchOnWindowFocus: false, retry: 1 });
  
  useEffect(() => {
    console.log(data);
    if (data) {
      setMessagingButtonStatus(data.messaging);
      setForwardingButtonStatus(data.forwarding);
    }
  }, [data]);

  const onMessagingChange = (val: boolean) => {
    setIsMessagingLoading(true);
    let path = val ? "start-messaging" : "stop-messaging";

    try {
      getAxiosInstance().get(`telegram-cron-job/${path}`).then(resp => {
        console.log(resp);
        if (resp) {
          setTimeout(() => {
            setIsMessagingLoading(false);
            setMessagingButtonStatus(val);
          }, 500);
        }
      })
    } catch(e) {
      openNotification("Произошла ошибка", String(e), "error", 5);
    }
  }

  const onForwardingChange = (val: boolean) => {
    setIsForwardingLoading(true);
    let path = val ? "start-forwarding" : "stop-forwarding";

    try {
      getAxiosInstance().get(`telegram-cron-job/${path}`).then(resp => {
        console.log(resp);
        if (resp) {
          setTimeout(() => {
            setIsForwardingLoading(false);
            setForwardingButtonStatus(val);
          }, 500);
        }
      })
    } catch(e) {
      openNotification("Произошла ошибка", String(e), "error", 5);
    }
  }
  
  return (<>
    <PageHeader title="Рассылка и отслеживание" onBack={() => navigate(-1)} className="page-header"/>
    <Layout className={"tableLayout"}>
      <Typography>
        <h2>Управление</h2>
      </Typography>
      <Space direction='horizontal' size="small">
        <Switch checked={forwardingButtonStatus} onChange={onForwardingChange} size="default" defaultChecked loading={isFetching || isForwardingLoading} style={{width: "50px" }} />
        <p style={{ marginBottom: 0 }}>
          <span style={{ color: forwardingButtonStatus ? "#7cb305" : "#cf1322" }}>{
            forwardingButtonStatus ? "Отслеживание сообщений включено" : "Отслеживание сообщений отключено"
          }</span>
        </p>
      </Space>
    </Layout>
  </>)
}