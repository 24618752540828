import { PageHeader, Layout, Skeleton, Empty, Space, Button, Upload } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import "./pages.scss";

import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from "react";
import { IStrapiResponseArray } from '../interfaces/strapi-response.interface';
import Search from 'antd/lib/input/Search';
import { config } from '../config';
import { useFetch } from '../hooks/use-fetch-2';
import { openNotification } from '../utils/open-notification';
import { IClientsChat } from '../interfaces/clients-chat.interface';
import { UploadOutlined } from '@ant-design/icons';
import { getUploadProps } from '../utils/get-upload-props';
import { IAccount } from '../interfaces/account.interface';
import { IAccountStrapiResponse } from '../interfaces/account-strapi-response.interface';

interface DataType {
  id: number;
  name: {
    id: number;
    name: string;
    link: string;
  };
  chat_id: number;
  accounts_count: number
}

const columns: ColumnsType<DataType> = [
  {
    title: "№",
    dataIndex: "id",
    key: "id",
    width: "6%",
    align: "center"
  },
  {
    title: "ID чата",
    dataIndex: "chat_id",
    key: "chat_id",
    width: "15%",
    sorter: (a, b) => a.chat_id - b.chat_id
  },
  {
    title: "Название",
    dataIndex: "name",
    key: "name",
    width: "35%",
    render: clients_chat => (
      <Space align="center" style={{display: "flex", justifyContent: "space-between", paddingRight: "15px"}}>
        <Link to={`/clients-chats/${clients_chat.id}`}>{clients_chat.name}</Link>
        <Button type="default" disabled={!clients_chat.link} onClick={() => window.open(clients_chat.link, "_blank")}>Открыть в телеграм</Button>
      </Space>
    ),
    sorter: (a, b) => {
      if (a.name.name > b.name.name) return 1;
      if (a.name.name < b.name.name) return -1;
      return 0;
    } 
  },
  {
    title: "Количество подключенных аккаунтов",
    dataIndex: "accounts_count",
    key: "accounts_count",
    width: "15%"
  }
]

export function ClientsChats() {
  const navigate = useNavigate();
  const [chats, setChats] = useState<DataType[]>([]);
  const [filteredChats, setFilteredChats] = useState<typeof chats>(chats);

  const fetchData = useFetch();

  useEffect(() => {
    getClientsData();
    
    async function getClientsData() {
      try {
        const chatsDataResponse = await fetchData.get<IStrapiResponseArray<IClientsChat>>(`service-chats?populate=*&pagination[page]=1&pagination[pageSize]=500`);
        const accountsDataResponse = await fetchData.get<IStrapiResponseArray<IAccountStrapiResponse>>('accounts?populate=*&pagination[page]=1&pagination[pageSize]=500');
        console.log("accountsDataResponse", accountsDataResponse);
        const clients_chat_ids: Array<number | undefined> = accountsDataResponse.data.data.map(item => item.attributes.clients_chat.data?.id);
        
        if (chatsDataResponse.data.data) {
          const arr = chatsDataResponse.data.data.map(item => ({
            ...item.attributes, 
            id: item.id,
            name: { id: item.id, name: item.attributes.name, link: item.attributes.link },
            accounts_count: clients_chat_ids.reduce((accumulator: number, currentValue) => {
              if (currentValue == item.id) return accumulator + 1;
              return accumulator;
            }, 0)
          }));
    
          setChats(arr);
        }
      } catch(e: any) {
        console.log(e);
        if (e?.response?.status == 401) openNotification("Необходима авторизация", undefined, "error");
      }
    }
  }, [fetchData]);

  useEffect(() => {
    setFilteredChats(chats);
  }, [chats]);

  const onSearch = (value: string) => {
    const filteredData = chats.filter(chat => {
      return Object.values(chat).find(val => {
        if (typeof val == "object" && val && val.name) {
          return String(val.name).toLocaleLowerCase().includes(value.toLocaleLowerCase());
        } else if (String(val).toLocaleLowerCase().includes(value.toLocaleLowerCase())) return true;
      });
    });

    setFilteredChats(filteredData);
  }

  return (<>
    <PageHeader title="Системные чаты" onBack={() => navigate(-1)} className="page-header"/>
      <Layout className={"tableLayout"}>
        <Space style={{ marginBottom: "20px", justifyContent: "space-between", width: "100%" }}>
          <Upload {...getUploadProps(`${config.api_url}/service-chats/batch-create`)}>
            <Button icon={<UploadOutlined />}>Загрузить чаты из csv</Button>
          </Upload>
          <Space align="end" direction="horizontal" style={{ justifyContent: "end"}}>
            <Button type="primary" onClick={() => window.open(`${config.admin_link}/content-manager/collectionType/api::service-chat.service-chat?page=1&pageSize=10&sort=id:ASC`, "_blank")}>Открыть в Strapi</Button>
            <Search 
              placeholder="Поиск по всем полям" 
              onSearch={(value, e) => onSearch(value)} 
              onInput={e => onSearch(e.currentTarget.value)} 
              style={{ width: 250 }} 
            />
          </Space>
        </Space>
        {chats.length ? <>
          {filteredChats.length ? 
            <Table 
              size="middle"
              className="table"
              columns={columns} 
              dataSource={filteredChats}
              pagination={{ pageSize: 50 }} 
            /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
          }
        </> : <Skeleton paragraph={{rows: 5}}/>}
      </Layout>
  </>)
}