import { Button, Empty, Layout, PageHeader, Skeleton, Space, Upload } from "antd"
import { useNavigate } from "react-router-dom";
import { getUploadProps } from "../utils/get-upload-props";
import { UploadOutlined } from '@ant-design/icons';
import { config } from "../config";
import Table, { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { useFetch } from "../hooks/use-fetch-2";
import { ITemplate } from "../interfaces/template.interface";
import { IStrapiResponseArray } from "../interfaces/strapi-response.interface";
import { openNotification } from "../utils/open-notification";

interface DataType {
  index: number;
  part: number;
  text: string;
  gender: "Муж." | "Жен." | "Оба"
}

const columns: ColumnsType<DataType> = [
  {
    title: "№",
    dataIndex: "index",
    key: "index",
    width: "6%",
    align: "center"
  },
  {
    title: "Часть шаблона",
    dataIndex: "part",
    key: "part",
    width: "6%"
  },
  {
    title: "Текст",
    dataIndex: "text",
    key: "text"
  },
  {
    title: "Пол",
    dataIndex: "gender",
    key: "gender",
    width: "10%"
  }
]

export function Templates() {
  const navigate = useNavigate();
  const [templates, setTemplates] = useState<DataType[]>([]);
  const [filteredTemplates, setFilteredTemplates] = useState(templates);
  const fetchData = useFetch();

  useEffect(() => {
    getTemplatesData();
    
    async function getTemplatesData() {
      try {
        const templatesDataResponse = await fetchData.get<IStrapiResponseArray<ITemplate>>(`templates`);
        console.log(templatesDataResponse.data.data);
        
        if (templatesDataResponse.data.data) {
          const arr = templatesDataResponse.data.data.map((item, i) => ({
              ...item.attributes, 
              index: i + 1
          }));
    
          setTemplates(arr);
        }
      } catch(e: any) {
        console.log(e);
        if (e?.response?.status == 401) openNotification("Необходима авторизация", undefined, "error");
      }
    }
  }, [fetchData]);

  useEffect(() => {
    setFilteredTemplates(templates);
  }, [templates]);

  return <>
    <PageHeader title="Шаблоны ответов" onBack={() => navigate(-1)} className="page-header"/>
      <Layout className={"tableLayout"}>
        <Space style={{ marginBottom: "20px", justifyContent: "space-between", width: "100%" }}>
          <Upload {...getUploadProps(`${config.api_url}/templates/batch-create`)}>
            <Button icon={<UploadOutlined />}>Загрузить шаблоны из csv</Button>
          </Upload>
        </Space>
        {templates ? 
        (filteredTemplates.length ? <Table 
          size="middle"
          className="table"
          columns={columns} 
          dataSource={filteredTemplates}
          pagination={{ pageSize: 50 }} 
        /> :  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>)
        : <Skeleton active paragraph={{ rows: 10 }} />}
      </Layout>
  </>
}