import 'antd/dist/antd.css';
import './App.scss';
import { Route, Routes } from 'react-router-dom';
import { Accounts } from './pages/accounts';
import { Account } from './pages/account';
import { Chat } from './pages/chat';
import { Chats } from './pages/chats';
import { ClientsChats } from './pages/clients-chats';
import { ClientsChat } from './pages/clients-chat';
import { Navigate } from 'react-router-dom';
import { SidebarMenu } from './components/sidebar-menu';
import { Login } from './pages/login';
import { ProtectedRoute } from './components/protected-route';
import { MainLayout } from './components/main-layout';
import { AuthProvider } from './auth-provider';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Telegram } from './pages/telegram';
import { ProxySettings } from './pages/proxy-settings';
import { Schedules } from './pages/schedules';
import { SystemAccount } from './pages/system-account';
import { Templates } from './pages/templates';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Routes>
          <Route element={<ProtectedRoute><MainLayout/></ProtectedRoute>}>
            <Route path="/" element={<Navigate to="/accounts"/>}/>
            <Route path="/accounts/" element={<Accounts/>}/>
            <Route path="/accounts/:id" element={<Account/>}/>
            <Route path="/chats" element={<div><Chats/></div>}/>
            <Route path="/chats/:id" element={<Chat/>}/>
            <Route path="/clients-chats" element={<ClientsChats/>}/>
            <Route path="/clients-chats/:id" element={<ClientsChat/>}/>
            <Route path="/telegram" element={<Telegram/>}/>
            <Route path="/proxy" element={<ProxySettings/>}/>
            <Route path="/schedules" element={<Schedules/>}/>
            <Route path="/system-account" element={<SystemAccount/>}/>
            <Route path='/templates' element={<Templates/>}/>
          </Route>
          <Route path="/login" element={<Login/>}/>
        </Routes>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
