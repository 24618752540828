import { Button, Checkbox, Form, Input, Space, Layout, Typography, notification } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { SendOutlined } from "@ant-design/icons";
import { useAuth } from '../hooks/use-auth';
import { config } from '../config';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { openNotification } from '../utils/open-notification';
import { useFetch } from '../hooks/use-fetch-2';


export function Login() {
  const { login } = useAuth();
  const fetchData = useFetch(false);

  const onFinish = async (values: { identifier: string, password: string }) => {
    try {
      const response = await fetchData.post(`/auth/local`, values);
      const data = await response.data;
      
      if (login) {
        openNotification("Успешно!", "Загрузка данных...", "success");
        setTimeout(() => {
          login(data.user.username, data.jwt);
        }, 1000);
      }
      
    } catch(e: any) {
      if (e?.response?.status == 400) {
        openNotification("Неверный логин или пароль");
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Layout className='login-layout'>

      <Content className="login-page__content">
          <Form
            className="login-form"
            size="large"
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Username"
              name="identifier"
              rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button className='login-form__button' type="primary" htmlType="submit">Войти</Button>
            </Form.Item>
          </Form>
      </Content>
    </Layout>
  );
};