import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./hooks/use-local-storage";

export const AuthContext = createContext<{ user: string | null, jwt: string | null, login?: (username: string, jwt: string) => void, logout?: () => void }>({ user: null, jwt: null });

export const AuthProvider = ({ children }: React.PropsWithChildren) => {
  const [user, setUser] = useLocalStorage("user", null);
  const [jwt, setJWT] = useLocalStorage("jwt", null);
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async (username: string, jwt: string) => {
    setUser(username);
    setJWT(jwt);
    navigate("/");
  };

  // call this function to sign out logged in user
  const logout = () => {  
    setUser(null);
    navigate("/", { replace: true });
  };

  const value = useMemo(() => ({
    user,
    jwt,
    login,
    logout
  }), [user]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};