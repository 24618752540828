import { Menu } from "antd"
import { Link } from "react-router-dom";
import { IMenuItem } from '../interfaces/menu-item.interface';

export function SidebarMenu({ items, activeItemKey }: { items: IMenuItem[], activeItemKey?: string }) {
  return (
    <Menu
      theme="dark"
      defaultSelectedKeys={[activeItemKey || "1"]}
      defaultOpenKeys={['1']}
      mode="inline"
    >
      {items.map(item => {
        if (item.children?.length) {
          return <Menu.SubMenu  icon={item.icon} key={item.key} title={item.label}>
            {item.children.map(child => (
              <Menu.Item key={child.key} icon={child.icon}>
                <Link key={child.key} to={child.link || ""}>
                  {child.label}
                </Link>
              </Menu.Item>
            ))}
          </Menu.SubMenu>
        }

        return <Menu.Item key={item.key} icon={item.icon}>{
          (item.link?.includes("http") || item.key == "admin") ? <a target="_blank" href={item.link}>{item.label}</a> :
            <Link key={item.key} to={item.link || ""}>{item.label}</Link>
        }</Menu.Item>

      })}
    </Menu>
  )
}