import { useParams, useNavigate, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button, Card, Divider, Empty, Layout, PageHeader, Skeleton, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { IClientsChat } from '../interfaces/clients-chat.interface';
import { IStrapiResponse } from '../interfaces/strapi-response.interface';
import { useFetch } from '../hooks/use-fetch-2';
import { openNotification } from '../utils/open-notification';
import { config } from '../config';

const columns: ColumnsType<Record<string, any>> = [
  {
    title: "№",
    dataIndex: "id",
    key: "id",
    width: "6%"
  },
  {
    title: "ID аккаунта",
    dataIndex: "account_id",
    key: "account_id",
    width: "15%",
    sorter: (a, b) => a.account_id - b.account_id
  },
  {
    title: "Имя",
    dataIndex: "name",
    key: "name",
    render: account => <Link to={`/accounts/${account.id}`}>{account.name}</Link>,
    sorter: (a, b) => {
      if (a.name.name > b.name.name) return 1;
      if (a.name.name < b.name.name) return -1;
      return 0;
    }
  },
  {
    title: "Телефон",
    dataIndex: "phone",
    key: "phone",
  }
]

export function ClientsChat() {
  let { id } = useParams();
  const navigate = useNavigate();
  const [chat, setChat] = useState<IClientsChat | null>(null);
  const [accounts, setAccounts] = useState<any[]>([]);

  const fetchData = useFetch();

  useEffect(() => {
    getClientData();
    
    async function getClientData() {
      try {
        const chatDataResponse = await fetchData.get<IStrapiResponse<IClientsChat>>(`service-chats/${id}?populate=*`);

        if (chatDataResponse?.data) {
          setChat(chatDataResponse.data.data?.attributes);
        }
      } catch(e: any) {
        console.log(e);
        if (e?.response?.status == 401) openNotification("Необходима авторизация", undefined, "error");
      }
    }
  }, [fetchData]);

  return (<>
    <PageHeader 
      title={chat?.name ? `Чат: ${chat?.name}` : <Skeleton.Input active size={"default"} />} 
      onBack={() => navigate(-1)} 
      className="page-header"
    />
    <Layout className={"tableLayout"}>
      {chat ? <Card className ="card" title={<>
        <span style={{marginRight: "15px"}}>ID чата: {chat?.chat_id}</span>
        <Button type="default" disabled={!chat.link} style={{marginRight: "20px"}} onClick={() => window.open(chat.link, "_blank")}>Открыть в телеграм</Button>
        <Button type="primary" onClick={() => window.open(`${config.admin_link}/content-manager/collectionType/api::service-chat.service-chat/${id}`, "_blank")}>Открыть в Strapi</Button>
      </>} bordered={false}>
        <Typography className='item-info'>
          <b>Название чата</b>: {chat?.name}<br/>
        </Typography>
      </Card> : 
      <Card title={<Skeleton.Button active size={"small"}/>} bordered={false}>
        <Skeleton paragraph active/>
        <Divider orientation="left">Таблица с чатами и постами</Divider>
        <Skeleton paragraph active/>
      </Card>
      }
    </Layout>
  </>)
}