import { Button, Form, Input, Layout, PageHeader, Skeleton, Space } from "antd";
import Typography from "antd/lib/typography/Typography";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getAxiosInstance } from "../hooks/use-fetch-2";
import { useEffect, useState } from 'react';
import { openNotification } from "../utils/open-notification";

interface IProxyForm {
  host: string;
  port: number;
  userId: string;
  password: string;
  type: number;
}

export function ProxySettings() {
  const navigate = useNavigate();
  const [sendButtonStatus, setSendButtonStatus] = useState("none");
  const [form] = Form.useForm<IProxyForm>();
  const { isFetching, data, error } = useQuery('cron-job', () =>
  getAxiosInstance().get(`general-proxy`).then(resp => {
    return resp.data?.data?.attributes;
  }).catch(e => {
    throw e.response?.data;
  }), { refetchOnWindowFocus: false, retry: 1 });

  useEffect(() => {
    console.log(form);
    if (data && form) form.setFieldsValue(data);
  }, [data, form]);

  async function onUpdateProxy(values: any) {
    setSendButtonStatus("loading");
    try {
      const resp = await getAxiosInstance().put('general-proxy', { data: { ...values }})
      console.log(resp);
      setSendButtonStatus("none");
      openNotification("Прокси настройки обновлены!", "", "success", 4);
    } catch(e) {
      setSendButtonStatus("none");
      openNotification(String(e), "", "error", 4);
    }
  } 
  
  return <>
    <PageHeader title="Прокси" onBack={() => navigate(-1)} className="page-header"/>
    <Layout className={"tableLayout"}>
      <Typography>
        <h2>Единый прокси для всех аккаунтов</h2>
      </Typography>
      {isFetching ? <Skeleton active paragraph={{rows: 4}}/> : <Space size="small" style={{marginBottom: "20px"}}>
         <Form 
          form={form}
          layout='horizontal' 
          initialValues={{
            host: "",
            port: 0,
            type: 5,
            userId: "",
            password: ""
          }}
          onFinish={onUpdateProxy} 
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}>
          <Form.Item label="host" name="host" style={{marginBottom: "10px"}}>
            <Input required placeholder="Введите хост" size="middle" style={{ width: '200px' }}/>
          </Form.Item>
          <Form.Item label="port" name="port" style={{marginBottom: "10px"}}>
            <Input placeholder="Введите порт" size="middle" style={{ width: '200px' }}/>
          </Form.Item>
          <Form.Item label="socks type" name="type" style={{marginBottom: "10px"}}>
            <Input placeholder="Введите socks: 4 или 5" size="middle" style={{ width: '200px' }}/>
          </Form.Item>
          <Form.Item label="userId" name="userId" style={{marginBottom: "10px"}}>
            <Input  placeholder="Введите userId" size="middle" style={{ width: '200px' }}/>
          </Form.Item>
          <Form.Item label="password" name="password" style={{marginBottom: "10px"}}>
            <Input placeholder="Введите пароль" size="middle" style={{ width: '200px' }}/>
          </Form.Item>
          <Form.Item style={{marginBottom: 0}} wrapperCol={{ offset: 8, span: 16 }}>
              <Button ghost
                loading={sendButtonStatus == "loading"} 
                disabled={sendButtonStatus == "disabled"}
                htmlType='submit' 
                type="primary" 
                size="middle"
                style={{width: "200px"}}
              >Задать</Button>
            </Form.Item>
        </Form>
      </Space>}
    </Layout>
  </>;
}