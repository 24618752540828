import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/use-auth";

export const ProtectedRoute = ({ children }: React.PropsWithChildren) => {
  const { user, jwt } = useAuth();

  if (!(user && jwt)) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
};