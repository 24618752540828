import { Button, Form, Input, Space, Tag, Typography } from 'antd';
import { IAccount } from '../interfaces/account.interface';
import { CloseCircleOutlined, CheckCircleOutlined, SendOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { useState, useEffect, useMemo, Fragment } from 'react';

import styles from "./account-info-block.module.scss";
import { openNotification } from '../utils/open-notification';
import { useFetch, getAxiosInstance } from '../hooks/use-fetch-2';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { IAccountStrapiResponse } from '../interfaces/account-strapi-response.interface';
import { ITagData } from '../interfaces/tag-data.interface';
import { defineCodeType } from '../utils/define-code-type';
import ReactMarkdown from 'react-markdown';

const { Text } = Typography;

interface IAccountInfoBlockProps {
  account: IAccountStrapiResponse;
  id?: string;
}

type AuthState = "penging" | "no" | "yes";

export function AccountInfoBlock({ account, id }: IAccountInfoBlockProps) {
  const [codeSent, setCodeSent] = useState(false);
  const [requestButtonStatus, setRequestButtonStatus] = useState("none");
  const [sendButtonStatus, setSendButtonStatus] = useState("none");
  const [passwordButtonStatus, setPasswordButtonStatus] = useState("none");
  const [phoneCodeHash, setPhoneCodeHash] = useState("");
  const fetchData = useFetch();
  const [tgAuth, setTgAuth] = useState<AuthState>("penging");
  const [passwordForm, setPasswordForm] = useState(false);
  
  const { isLoading, isSuccess, status, isFetching, error, data: authData } = useQuery('checkAuth', () =>
    getAxiosInstance().get(`accounts/${id}/check-auth`).then(resp => {
      return resp.data;
    }).catch(e => {
      throw e.response?.data;
    }),
  { refetchOnWindowFocus: false, retry: 1 });

  useEffect(() => {

    if (isFetching) {
      return setTgAuth("penging");
    }

    if (authData) setTgAuth("yes");
      else setTgAuth("no");

    if (error) openNotification("Произошла ошибка", String(error), "error", 7);
  }, [authData, error, isFetching, status]);

  const tagData: ITagData = useMemo(() => {
    if (tgAuth == "no") return {
      icon: <CloseCircleOutlined />,
      color: "error",
      text: "Не авторизован"
    }

    if (tgAuth == "yes") return {
      icon: <CheckCircleOutlined />,
      color: "success",
      text: "Авторизован"
    }

    return {
      icon: <ClockCircleOutlined />,
      color: "default",
      text: "проверка...",
    }
  }, [tgAuth]);

  async function onRequestCode(e: React.MouseEvent) {
    setRequestButtonStatus("loading");
    try {
      const response = await fetchData<{ phone_code_hash: string, type: string, error?: { error_message: number } }>(`accounts/${id}/get-code`);
  
      if (response.data.phone_code_hash) {
        setPhoneCodeHash(response.data.phone_code_hash);
        setRequestButtonStatus("disabled");
        
        openNotification("Код отправлен!", defineCodeType(response.data.type), "success", 4);
        setCodeSent(true);

      } else if (response.data.error) {
        openNotification("Произошла ошибка", String(response.data.error?.error_message), "error", 7);
        setRequestButtonStatus("none");
      }
    } catch(e) {
      openNotification("Произошла ошибка", String(e), "error", 7);
      setRequestButtonStatus("none");
    }
  }

  async function onSendCode(values: { code: string }) {
    setSendButtonStatus("loading");

    if (values.code && phoneCodeHash) {
      try {
        const response = await fetchData(`accounts/${id}/sign-in`, {
          method: "POST",
          data: { code: values.code, phone_code_hash: phoneCodeHash }
        });

        console.log(response);
        const result = response.data?.result;

        if (result == "ok") {
          setSendButtonStatus("disabled");
          openNotification("Код успешно активирован!", "", "success", 4);
          setTgAuth("yes");
        }

        if (result == "invalid_code") {
          openNotification("Указан неверный код", "", "error", 4);
          setCodeSent(false);
          setRequestButtonStatus("none");
          setSendButtonStatus("none");
        }

        if (result == "password_needed") {
          openNotification("Необходимо ввести пароль", "", "warning", 4);
          setSendButtonStatus("disabled");
          setCodeSent(false);
          setPasswordForm(true);
        }

        if (result == "error" || !result) {
          openNotification("Произошла ошибка", String(response.data?.error), "error", 4);
          setCodeSent(false);
          setRequestButtonStatus("none");
          setSendButtonStatus("none");
        }

      } catch(e) {
        openNotification("Произошла ошибка", String(e), "error", 4);
        setCodeSent(false);
        setRequestButtonStatus("none");
        setSendButtonStatus("none");
      }
    }
  }

  const onSendPassword = async (values: { password: string }) => {
    setPasswordButtonStatus("loading");
    if (values.password) {
      try {
        const response = await fetchData(`accounts/${id}/password`, {
          method: "POST",
          data: { password: values.password }
        });

        if (response) {
          setPasswordButtonStatus("disabled");
          openNotification("Код успешно активирован!", "", "success", 4);
          setTgAuth("yes");
        }
      } catch (error) {
        openNotification("Произошла ошибка соединения", String(error), "error", 4);
        setCodeSent(false);
        setPasswordForm(false);
        setRequestButtonStatus("none");
        setPasswordButtonStatus("none");
      }
    }
  }

  return (<>
    <b>Имя</b>: {account?.name}<br/>
    <b>Системный чат</b>: {account.clients_chat?.data ? 
      <Link to={`/clients-chats/${account.clients_chat?.data.id}`} >{account.clients_chat.data.attributes.name}</Link> : "нет"}
      <br/>

    <Space align="center">
      <div style={{height: "30px"}}><b>Статус в системе: </b></div>

      <Tag style={{height: "24px", marginRight: 0, marginBottom: "3px"}}
        icon={tagData.icon} 
        color={tagData.color}
      >{tagData.text}
      </Tag>

      {tgAuth == "no" && <Button 
        loading={requestButtonStatus == "loading"} 
        disabled={requestButtonStatus == "disabled"}
        type="primary" 
        icon={<SendOutlined />} 
        size={"small"} 
        onClick={onRequestCode} 
        danger
      >Запросить код
      </Button>}

      {(codeSent && tgAuth == "no") && <Form layout='horizontal' className={styles.form} onFinish={onSendCode}>
        <Input.Group>
          <Space>
            <Form.Item name="code" style={{marginBottom: 0}}>
                <Input placeholder="Введите код" size="small" style={{ width: '110px' }}/>
            </Form.Item>
            <Form.Item style={{marginBottom: 0}}>
              <Button ghost
                loading={sendButtonStatus == "loading"} 
                disabled={sendButtonStatus == "disabled"}
                htmlType='submit' 
                type="primary" 
                size="small"
              >Отправить</Button>
            </Form.Item>
          </Space>
        </Input.Group>
      </Form>}

      {(passwordForm && tgAuth == "no") && <Form layout='horizontal' className={styles.form} onFinish={onSendPassword}>
      <Input.Group>
          <Space>
            <Form.Item name="password" style={{marginBottom: 0}}>
                <Input placeholder="Введите пароль" size="small" style={{ width: '160px' }}/>
            </Form.Item>
            <Form.Item style={{marginBottom: 0}}>
              <Button ghost
                loading={passwordButtonStatus == "loading"} 
                disabled={passwordButtonStatus == "disabled"}
                htmlType='submit' 
                type="primary" 
                size="small"
              >Отправить</Button>
            </Form.Item>
          </Space>
        </Input.Group>

      </Form>}
    </Space>

    <br/>
    <b>Продавец</b>: {account?.seller}<br/>
    <b>Объявление</b>:<br/>
    <p style={{whiteSpace: "pre-wrap", lineHeight: 1.3, maxWidth: "100%"}}>{account.text}</p>
  </>
  )
}
